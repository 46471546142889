<template>
    <div class="app-container">
        <el-card class="box-card">
            <div slot="header" class="categ-access-header">
                <div>
                    {{ $t('account.categoryaccess.title') }}
                </div>
                <div>
                    <el-button type="primary" size="mini" @click="dllCommissionsList">
                        {{ $t('account.categoryaccess.comm-list') }}
                    </el-button>
                </div>
            </div>
            <div class="category-lists-container">
                <categories-access></categories-access>
            </div>
        </el-card>
    </div>
</template>

<script>
import { downloadCommissionsList } from '@/api/cont';
import { _base64ToArrayBuffer } from '@/utils/index.js';
const CategoriesAccess = () => import('@/components/CategoriesAccess/categories');

export default {
    components: {
        CategoriesAccess,
    },
    data() {
        return {
            currentCategory: [],
        };
    },
    methods: {
        async dllCommissionsList() {
            try {
                const result = await downloadCommissionsList();
                const csvString = unescape(encodeURIComponent(result.message));
                const text = _base64ToArrayBuffer(csvString);
                const a = window.document.createElement('a');
                a.href = window.URL.createObjectURL(
                    new Blob([text], {
                        type: 'text/csv',
                    })
                );
                a.download = 'cel_mp_commissions.csv';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            } catch (err) {
                console.log(err);
                this.$reportToSentry(err, {
                    extra: {
                        fn: 'dowloadCommissionsList',
                        params: {},
                    },
                });
            }
        },
    },
    computed: {},
    watch: {},
    created() {},
};
</script>
<style rel="stylesheet/scss" lang="scss">
@import './src/styles/modules/cont.scss';

.categ-access-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
</style>
